<template>
    <div class="vx-col md:w-full sm:w-auto">
        <div class="vx-row md:w-full">
            <vx-tooltip class="position-icon" text="Cài đặt" position="top">
                <feather-icon icon="ClockIcon" class="action-success icon-btn"
                              svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                              @click="goToSetting"/>
            </vx-tooltip>

        </div>
    </div>
</template>

<script>
export default {
    name: 'CellRendererActions',
    methods: {
        goToSetting() {
            this.$router.push(`/user/setting-work-shift-by-day?branch_id=${this.params.data.organizationBranchId}&id=${this.params.data.employeeId}`).catch(() => {
            })
        }
    }
}
</script>
<style scoped>

</style>
