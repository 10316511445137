<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <div class="flex-grow">
                <div class="vx-col md:w-1/4 sm:w-auto mr-4">
                    <label class="vs-input--label">Chi nhánh</label>
                    <v-select class="" v-model="selectedOrganizationBranch" :clearable="false"
                              :options="organizationBranchOptions">
                              <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                    </v-select>
                </div>
            </div>
            <div class="flex flex-wrap items-center mt-5">
                <div class="flex-grow">

                </div>

                <!-- ITEMS PER PAGE -->
                <Pagination
                    :itemList="employeeData"
                    :currentPage="currentPage"
                    :paginationPageSize="paginationPageSize"
                    :gridApi="gridApi"
                />

                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery" placeholder="Tìm kiếm..."/>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="employeeData"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="paginationPageSize"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import Pagination from "../../ui-elements/Pagination.vue"

export default {
    components: {
        AgGridVue,
        vSelect,
        // Cell Renderer
        CellRendererActions,
        Pagination
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'Mã nhân viên',
                    field: 'employeeCode',
                    width: 100
                },
                {
                    headerName: 'Tên nhân viên',
                    field: 'fullName',
                    width: 200
                },
                {
                    headerName: 'Email',
                    field: 'email',
                    width: 200,
                },
                {
                    headerName: 'Số điện thoại',
                    field: 'phoneNumber',
                    width: 200,
                },
                {
                    headerName: 'Hành động',
                    field: 'actions',
                    width: 100,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions,
            },
            employeeData: [],
            organizationBranchOptions: [],
            selectedOrganizationBranchValue: null
        }
    },
    created() {
        this.initScreen()
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateEmployee'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_EMPLOYEE', false);
            }
        }
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.selectedOrganizationBranchValue),
                    value: this.selectedOrganizationBranchValue
                }
            },
            set(obj) {
                this.selectedOrganizationBranchValue = obj.value
                this.loadEmployee()
            }
        }
    },
    methods: {
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value === id);
            return (organizationBranch && organizationBranch.label) || 'Chưa xác định';
        },
        addEmployee() {
            this.$router.push(`/user/add-employee?branch_id=${this.selectedOrganizationBranchValue}`).catch(() => {
            });
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
            if (branchList.length === 0) {
                return this.$vs.notify({
                    text: 'Hiện tại không tồn tại chi nhánh nào',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            this.organizationBranchOptions = branchList.map(item => {
                return {value: item.id, label: item.organizationBranchName}
            });
            let branchId = this.$route.query.branch_id;
            let index = 0;
            if (branchId) {
                index = this.organizationBranchOptions.findIndex(item => item.value == branchId)
            }
            this.selectedOrganizationBranch = this.organizationBranchOptions[index > -1 ? index : 0];

        },
        loadEmployee() {
            if (!this.selectedOrganizationBranchValue) return;

            this.$vs.loading();
            this.$crm.post('/employee/find-by-organization-branch/' + this.selectedOrganizationBranchValue).then((response) => {
                this.employeeData = response.data;
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>

<style scoped>

</style>
